import React, { Component } from "react";
import styled from "@emotion/styled";

type Props = {};
const tabletBreakpoint = "900px";

export class IconContainer extends Component<Props> {
    render() {
        return (
            <Container>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/weight.png?tr=w-300" />
                    <br />
                    <br />
                    <section>Get optimized workout routines</section>
                </div>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/run.png?tr=w-300" />
                    <br />
                    <br />
                    <section>
                        Join millions of other people who are working out with
                        Fitplan
                    </section>
                </div>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/tag.png?tr=w-300" />
                    <br />
                    <br />
                    <section>
                        GRAB THE DISCOUNT BUNDLE BEFORE IT'S GONE!
                    </section>
                </div>
            </Container>
        );
    }
}

const Container = styled.div`
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    div {
        display: inline-block;
        width: 33vw;
        height: 22vw;
        max-width: 300px;
        max-height: 200px;
        vertical-align: top;
        margin: 0 auto;
    }
    img {
        height: 20vw;
        width: 20vw;
        max-height: 120px;
        max-width: 120px;
        vertical-align: top;
        display: block;
        margin: 0 auto;
    }
    section {
        width: 33vw;
        max-width: 300px;
        text-transform: uppercase;
        font-size: 2.5vw;
    }
    small {
        font-size: 80%;
    }
    @media (min-width: ${tabletBreakpoint}) {
        section {
            font-size: 21px;
        }
    }
`;
