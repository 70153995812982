import * as React from "react";
import styled from "@emotion/styled";

import "../../subscribe.scss";

import { AthleteImages } from "../../../components/landing/email/Athletes";
import { HomeWorkouts } from "../../../components/landing/social/HomeWorkouts";

import { IconContainer } from "../../../components/landing/email/IconPoints";
import { PurchaseContainer } from "../../../components/landing/email/PurchaseContainer";
interface Props {}
const tabletBreakpoint = "900px";
const mobileBreakpoint = "700px";

const EmailSubscribePage: React.FunctionComponent<Props> = props => {
    return (
        <LocalWrapper>
            <MobileTitle>
                Get in great shape without leaving your house or going to the
                gym.
            </MobileTitle>
            <HeroImage>
                <div className="padding"></div>
                <img className="desktop-img" src="https://images.fitplanapp.com/web/landing/email/banner.jpg?tr=w-2200" />
                <img className="mobile-img" src="https://images.fitplanapp.com/web/landing/email/banner-vertical.jpg?tr=w-1100" />
            </HeroImage>
            <GradientContainer>
                <BreakDesktop />
                <DesktopTitle>
                    Get in great shape without leaving your house or going to
                    the gym.
                </DesktopTitle>
                <br />
                <Title>Get 25% Off All Merchandise with an Annual Subscription</Title>
                <br />
                <PurchaseContainer />
                <br />
                <MissionStatement>
                    25% off discount code will be emailed to you after purchase.
                    <br />
                    For use at our <a href="https://store.fitplanapp.com/">online store</a>
                </MissionStatement>
                <br />
                <MissionStatement>
                    You will get access to workout routines from top tier
                    professional athletes and celebrity trainers. Have their
                    lifetime of experience in the palm of your hand every day,
                    ready to help you unlock your dream body. Join now.
                </MissionStatement>
                <br />
                <MissionStatement>
                    WE’RE BETTER AND STRONGER <b>TOGETHER</b>.
                </MissionStatement>

                <br />
                <br />
                <IconContainer />
                <br />
                <br />
                <br />
                <br />

                <Title>
                    TRY ONE OF OUR COMMUNITY <br />
                    FAVORITE HOME WORKOUT PLANS
                </Title>
                <br />
                <HomeWorkouts />
                <br />
                <Title>
                    CLICK TO FIND MORE <BreakMobile />
                    FROM OUR TRAINERS!
                </Title>
                <AthleteImages />
                <BreakDesktop />
                <BreakDesktop />
                <PurchaseContainer />
                <br />
                <br />
                <br />
                <br />
                <br />
            </GradientContainer>
        </LocalWrapper>
    );
};

const GifImg = styled.img`
    width: 29vw !important;
    max-width: 290px;
    margin-top: 5% !important;
`;

const HeroImage = styled.div`
    position: relative;
    width: 100vw;
    overflow: hidden;
    min-height: 70vh;

    .padding {
        width: 100%;
        padding-bottom: 170%;
    }

    .desktop-img {
        display: none;
        object-position: center
    }

    .mobile-img {
        object-position: center top;
    }

    @media (min-width: ${tabletBreakpoint}) {
        min-height: 0;
         
        .padding {
            padding-bottom: 31%;
        }
        .desktop-img {
            display: block;
        }

        .mobile-img {
            display: none;
        }

        height: auto;

        img {
            height: auto;
        }
    }

    img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

`;
const GradientContainer = styled.div`
    box-shadow: inset 0 7px 9px -7px rgba(128, 130, 177, 0.4);
    position: relative;

    @media (min-width: ${tabletBreakpoint}) {
    }
`;

const LocalWrapper = styled.div`
    font-family: Barlow;

    color: #1d222b;
    background: #fff;

    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    position: fixed;
`;

const BreakMobile = styled.br`
    @media (min-width: ${tabletBreakpoint}) {
        display: none;
    }
`;
const BreakDesktop = styled.br`
    display: none;
    @media (min-width: ${tabletBreakpoint}) {
        display: block;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    width: 100%;
    text-transform: uppercase;
    @media (min-width: ${tabletBreakpoint}) {
        font-weight: 800;
        font-size: 36px;
    }
`;
const MobileTitle = styled(Title)`
    color: #fff;
    background-color: #202122;
    padding: 4px 8px 4px 8px;
    border: none;
    @media (min-width: ${tabletBreakpoint}) {
        padding-top: 10px;
    }
`;
const DesktopTitle = styled(Title)`
    display: none;
    @media (min-width: ${tabletBreakpoint}) {
        display: none;
    }
`;

const MissionStatement = styled.p`
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 5%;
    @media (min-width: ${tabletBreakpoint}) {
        padding: 0;
    }
`;

export default EmailSubscribePage;
